import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@slal/ui/theme-provider';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from '~/components';
import { AppRoutes } from '~/routes';
import { client } from '~/graphql';
import { AnalyticsProvider } from './components/AnalyticsProvider';

const App = () => {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider>
                <BrowserRouter>
                    <AnalyticsProvider>
                        <ScrollToTop />
                        <AppRoutes />
                    </AnalyticsProvider>
                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default App;
