import { DocumentNode, gql } from '@apollo/client';

export const GetInvestmentOptions: DocumentNode = gql`
    query GetInvestmentOptions {
        getInvestmentOptions {
            funds {
                cashLikeFund
                factsheetUrl
                fundCode
                fundName
                totalExpenseRate
                volatilityRating
            }
            investmentPathways {
                factsheetUrl
                fundCode
                fundName
                totalExpenseRate
            }
        }
    }
`;
