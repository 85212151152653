import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_APPLICATION_RESPONSE } from '~/hooks';

const useRedirectRules = (): string | undefined => {
    const { pathname } = useLocation();
    const createApplicationResponse = sessionStorage.getItem(CREATE_APPLICATION_RESPONSE);
    if (!!createApplicationResponse && pathname !== '/application/confirmation') return '/application/confirmation';
};

export const ApplicationRedirect = () => {
    const navigate = useNavigate();
    const redirectPath = useRedirectRules();
    useEffect(() => {
        if (!redirectPath) return;
        navigate(redirectPath);
    }, [navigate, redirectPath]);

    return null;
};

export default ApplicationRedirect;
