import { PropsWithChildren } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { CookieBanner } from '../CookieBanner';

export const Layout = ({ children }: PropsWithChildren) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
            <CookieBanner />
        </>
    );
};
