import { FundAllocation } from './funds';

export enum ApplicationFormKeys {
    BANK_DETAILS = 'bankDetails',
    CASH_LIKE_FUND_DECLARATION = 'cashLikeFundDeclaration',
    DECLARATION = 'declaration',
    DRAWDOWN_FUND_ALLOCATIONS = 'drawdownFundAllocations',
    DRAWDOWN_INVESTMENT_OPTIONS = 'drawdownInvestment',
    DRAWDOWN_INVESTMENT_PATHWAY = 'drawdownInvestmentPathway',
    GUIDANCE = 'guidance',
    KEY_CONSIDERATIONS = 'keyConsiderations',
    LUMP_SUM_OPTIONS = 'lumpSumOptions',
    PERSONAL_INFORMATION = 'personalInformation',
    PENSION_TRANSFER = 'pension',
    REGULAR_INCOME = 'regularIncome',
    SAVINGS_INVESTMENT_OPTIONS = 'savingsInvestment',
    SAVINGS_INVESTMENT_PATHWAY = 'savingsInvestmentPathway',
    SAVINGS_FUND_ALLOCATIONS = 'savingsFundAllocations',
    WITHDRAWAL_OPTIONS = 'withdrawal',
    SCHEME = 'scheme',
}

export type FormKeyProps = {
    formKey: ApplicationFormKeys;
};

export type BankDetailsFormValues = {
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
    buildingSocietyRollNumber?: string;
    sortCode: string;
};

export type BankDetailsSchema = {
    [ApplicationFormKeys.BANK_DETAILS]: BankDetailsFormValues;
};

export type GuidanceFormValues = {
    guidanceReceived: string;
};

export type GuidanceSchema = {
    [ApplicationFormKeys.GUIDANCE]: GuidanceFormValues;
};

export type OptionsFormValues = {
    option: string;
};

export type AdditionalDetailsFormValues = {
    addressLineOne: string;
    addressLineTwo: string;
    addressLineThree?: string;
    postcode: string;
    maritalStatus: string;
    nationalInsuranceNumber: string;
};

export type AdditionalDetailsSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: AdditionalDetailsFormValues;
};

export type PersonalInformationFormValues = {
    title: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    emailAddress: string;
    phoneNumber: string;
};

export type PersonalInformationSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: PersonalInformationFormValues;
};

export type CompletePersonalInformationValues = PersonalInformationFormValues & AdditionalDetailsFormValues;

export type CompletePersonalInformationSchema = {
    [ApplicationFormKeys.PERSONAL_INFORMATION]: CompletePersonalInformationValues;
};

export enum TransferAmountOptions {
    MAXIMUM_PENSION_VALUE = 'MAXIMUM_PENSION_VALUE',
    HALF_PENSION_VALUE = 'HALF_PENSION_VALUE',
    SPECIFY = 'SPECIFY',
}

export type PensionFormValues = {
    policyNumber: string;
    provider: string;
    value: number | string;
    specifiedProvider?: string;
    moneyWithdrawn: string;
    transferAmountOption: TransferAmountOptions | string;
    specifiedTransferAmount?: number | string;
    declaration: boolean;
};

export type PensionSchema = {
    [ApplicationFormKeys.PENSION_TRANSFER]: PensionFormValues;
};

export type WithdrawalOptionsSchema = {
    [ApplicationFormKeys.WITHDRAWAL_OPTIONS]: OptionsFormValues;
};

export enum TaxFreeCashLevel {
    UNDER = 'UNDER',
    OVER = 'OVER',
    MAX = 'MAX',
}

export enum LumpSumOptions {
    MAXIMUM_TAX_FREE_CASH = 'MAXIMUM_TAX_FREE_CASH',
    HALF_TAX_FREE_CASH = 'HALF_TAX_FREE_CASH',
    SPECIFY = 'SPECIFY',
}

export type LumpSumOptionsFormValues = {
    option: LumpSumOptions | string;
    specifiedAmount?: number | string;
};

export type LumpSumOptionsSchema = {
    [ApplicationFormKeys.LUMP_SUM_OPTIONS]: LumpSumOptionsFormValues;
};

export type ApplicationPots = {
    drawdown: number;
    savings?: number;
};

export type ApplicationPensionWithdrawalValues = {
    lumpSumOption: LumpSumOptions | string;
    lumpSum: number;
    regularIncome?: number;
    regularIncomePaymentDay?: number;
};

export type ApplicationPensionValuesInput = {
    pension?: ApplicationSchema[ApplicationFormKeys.PENSION_TRANSFER];
    lumpSumOptions?: ApplicationSchema[ApplicationFormKeys.LUMP_SUM_OPTIONS];
    regularIncome?: RegularIncomeFormValues;
    withdrawal?: ApplicationSchema[ApplicationFormKeys.WITHDRAWAL_OPTIONS];
};

export type ApplicationPensionValues = {
    halfTaxFreeCash: number;
    maximumLumpSum: number;
    maximumRegularIncome: number;
    maximumTaxFreeCash: number;
    maximumTaxFreeCashTaken: boolean;
    policyNumber?: string;
    pots?: ApplicationPots;
    remainderAfterLumpSum?: number;
    transferValue: number;
    total: number;
    withdrawals?: ApplicationPensionWithdrawalValues;
};

export type RegularIncomeFormValues = {
    amount: number | string;
    day: number | string;
};

export type RegularIncomeSchema = {
    [ApplicationFormKeys.REGULAR_INCOME]: RegularIncomeFormValues;
};

export enum InvestmentOptions {
    DEFAULT = 'DEFAULT',
    CUSTOM = 'CUSTOM',
}

export type InvestmentOptionsFormValues = {
    option: InvestmentOptions | string;
};

export type SavingsInvestmentOptionsSchema = {
    [ApplicationFormKeys.SAVINGS_INVESTMENT_OPTIONS]: InvestmentOptionsFormValues;
};

export type DrawdownInvestmentOptionsSchema = {
    [ApplicationFormKeys.DRAWDOWN_INVESTMENT_OPTIONS]: InvestmentOptionsFormValues;
};

export type SavingsInvestmentPathway = {
    fundCode: string;
    fundName: string;
};

export type CashLikeFundDeclarationFormValues = {
    consent: boolean;
};

export type ApplicationDeclarationFormValues = {
    applicationCopyConsent: boolean;
    informationVerificationConsent: boolean;
};

export type DeclarationSchema = {
    [ApplicationFormKeys.DECLARATION]: ApplicationDeclarationFormValues;
};

export type CashLikeFundDeclarationSchema = {
    [ApplicationFormKeys.CASH_LIKE_FUND_DECLARATION]?: CashLikeFundDeclarationFormValues;
};

export type SavingsFundAllocationsSchema = {
    [ApplicationFormKeys.SAVINGS_FUND_ALLOCATIONS]?: FundAllocation[];
};

export type DrawdownFundAllocationsSchema = {
    [ApplicationFormKeys.DRAWDOWN_FUND_ALLOCATIONS]?: FundAllocation[];
};

export type ApplicationContextInvestmentOptions = {
    drawdown?: InvestmentOptionsFormValues['option'];
    savings?: InvestmentOptionsFormValues['option'];
};

export type ApplicationContextProps = {
    investmentOptions: ApplicationContextInvestmentOptions;
    pensionValues: ApplicationPensionValues;
    withdrawalOption?: string;
};

export enum DrawdownInvestmentPathwayOption {
    OPTION_1 = 'KLIB',
    OPTION_2 = 'NJEE',
    OPTION_3 = 'LCKK',
    OPTION_4 = 'GBMC',
}

export type DrawdownInvestmentPathwayOptionFormValues = SavingsInvestmentPathway;

export type DrawdownInvestmentPathwayOptionSchema = {
    [ApplicationFormKeys.DRAWDOWN_INVESTMENT_PATHWAY]: DrawdownInvestmentPathwayOptionFormValues;
};

export enum MainPlanOptions {
    SPEND = 'Spend it',
    REDUCE_DEBT = 'Reduce debt with it',
    LIVE = 'Live on it',
    SAVE = 'Save or invest it',
}

export type KeyConsiderationsSchema = {
    [ApplicationFormKeys.KEY_CONSIDERATIONS]: {
        [key: string]: string;
    };
};

export type ApplicationSchemeValues = {
    id: string;
    dataUsagePreference: boolean;
};
export type SchemeSchema = {
    [ApplicationFormKeys.SCHEME]: ApplicationSchemeValues;
};
export type ApplicationBackRouteKeys = 'investment-options' | 'key-considerations-information';

// Keep this up to date with all schema types
export type ApplicationSchema = GuidanceSchema &
    BankDetailsSchema &
    CompletePersonalInformationSchema &
    PensionSchema &
    WithdrawalOptionsSchema &
    SchemeSchema &
    LumpSumOptionsSchema & {
        [ApplicationFormKeys.REGULAR_INCOME]: RegularIncomeFormValues | object;
    } & DrawdownInvestmentOptionsSchema & {
        [ApplicationFormKeys.SAVINGS_INVESTMENT_OPTIONS]?: InvestmentOptionsFormValues;
    } & {
        [ApplicationFormKeys.SAVINGS_INVESTMENT_PATHWAY]?: SavingsInvestmentPathway;
    } & {
        [ApplicationFormKeys.SAVINGS_FUND_ALLOCATIONS]?: FundAllocation[];
    } & {
        [ApplicationFormKeys.DRAWDOWN_FUND_ALLOCATIONS]?: FundAllocation[];
    } & {
        [ApplicationFormKeys.DRAWDOWN_INVESTMENT_PATHWAY]?: DrawdownInvestmentPathwayOptionFormValues | object;
    } & DeclarationSchema;

export type CreateApplicationResponse = {
    estimatedTimescales: string | null;
    nextSteps: string[];
};
