import { styled } from '@slal/ui';
import { AccordionSection } from '@slal/ui/display';
import { breakpointUp } from '@slal/ui/helpers';
import { Heading, Li, P, Ul } from '@slal/ui/typography';

export const StyledUl = styled(Ul)`
    padding-left: 0;
`;

export const StyledLi = styled(Li)`
    list-style: none;

    span {
        display: flex;
        gap: ${({ theme: { space } }) => space[4]};
        align-items: center;
    }
    svg {
        min-width: 18px;
        min-height: 18px;
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    h5 {
        margin-bottom: ${({ theme: { space } }) => space[4]};
    }
`;

export const StyledDefaultFundSavingsGraph = styled.img`
    max-width: 100%;
    height: 175px;
    ${breakpointUp.sm`
        height: 300px; 
    `}
    margin-bottom: ${({ theme: { space } }) => space[5]};
`;

export const DefaultSavingsFundChart = styled.figure`
    margin: ${({ theme: { space } }) => space[5]} 0 0 0;
`;

export const ChartKeyWrapper = styled.li`
    display: flex;
    align-items: center;
    height: 70px;
    padding-bottom: ${({ theme: { space } }) => space[5]};
    width: 100%;
    ${breakpointUp.sm`
        width: 70%; 
    `}
    ${breakpointUp.md`
        width: 100%; 
    `}
    ${breakpointUp.lg`
        width: 80%; 
    `}
    ${breakpointUp.xl`
        width: 60%; 
    `}
`;

export const ColorIndicator = styled.div`
    background-color: ${({ color }) => color};
    width: 20px;
    height: 100%;
`;

export const TextSection = styled.div`
    flex: 1;
    margin-left: 16px;
`;

export const ChartKeyTitle = styled.div`
    margin-bottom: 0;
    font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
    ${breakpointUp.xs`
        margin-bottom: ${({ theme: { space } }) => space[4]};
        font-size: ${({ theme: { fontSizes } }) => fontSizes[4]};   
    `}
`;

export const Subtitle = styled(P)`
    font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
    ${breakpointUp.xs`
        font-size: ${({ theme: { fontSizes } }) => fontSizes[4]};   
    `}
`;

export const ChartKeysWrapper = styled(Ul)`
    padding-left: 0;
`;

export const ChartKeyHeading = styled(Heading)`
    margin-top: ${({ theme: { space } }) => space[5]};
`;
